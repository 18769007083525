export const testimonials = [
  {
    id: 1,
    title: '"A game-changer for startups! Our MVP was delivered flawlessly, exceeding our expectations”',
    description: 'The first client guarantee gave us the confidence boost we needed.',
    authorName: 'Jordan T.',
    authorImage: 'https://api.picogen.io/files/202401/19/ee8f3c72cc7f801433ce92054461e719.png',
  },
  {
    id: 2,
    title:
      '"Our SaaS journey started with the right partner – exceptional development services and invaluable guidance!”',
    description: 'The project launch on popular resources was a major win for us.',
    authorName: 'Melissa R.',
    authorImage: 'https://api.picogen.io/files/202401/19/113bab1d5de50cc6051b19cb4b025266.png',
  },
  {
    id: 3,
    title: '"Turning our vision into reality, they provided top-notch software solutions that truly set us apart.”',
    description: 'Their commitment to our success was evident from the start.',
    authorName: 'Alex P.',
    authorImage: 'https://framerusercontent.com/images/6tfmnbdDWP4QufkY4OYxy8wsow.webp',
  },
  {
    id: 4,
    title: '"Launching our online business was seamless thanks to their expertise and the first client guarantee.”',
    description: "We couldn't have asked for a better experience.",
    authorName: 'Samantha K.',
    authorImage: 'https://api.picogen.io/files/202401/19/ab53e6eaa6a0f0b7e5e143aa13cb5f9f.png',
  },
]
