import styles from './index.module.scss'
import { ButtonSizes, ButtonVariants } from './types'

type ButtonProps = {
  type?: ButtonVariants
  size?: ButtonSizes
  onClick?: any
  label?: string
  disabled?: boolean
  isSubmit?: boolean
}

export function Button({
  type = ButtonVariants.primary,
  disabled = false,
  size = ButtonSizes.md,
  onClick,
  isSubmit = false,
  label,
}: ButtonProps) {
  return (
    <button
      type={!isSubmit ? 'button' : 'submit'}
      disabled={disabled}
      onClick={onClick}
      className={`
        ${styles.button}
        ${styles[type]}
        ${styles[size]}
			`}
    >
      {label}
    </button>
  )
}
