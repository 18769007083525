import { ReactLocation, Route } from '@tanstack/react-location'
import { MetaTitle } from './components/MetaTitle'
import { BlogPageOne } from './pages/BlogPosts/BlogOne'
import { BlogPageThree } from './pages/BlogPosts/BlogThree'
import { BlogPageTwo } from './pages/BlogPosts/BlogTwo'
import { LandingPage } from './pages/Landing'
import { TeamScreen } from './pages/team'

export const location = new ReactLocation()

enum ROUTES {
  landing = '/',
  login = 'login',
  signup = 'signup',
}

export const routes: Route[] = [
  {
    path: ROUTES.landing,
    element: <LandingPage />,
  },
  {
    path: ROUTES.login,
    element: (
      <div>
        <MetaTitle title="Login" />
        <h1>Login</h1>
      </div>
    ),
  },
  {
    path: 'how-to-validate-your-business-idea-with-well-designed-mvp',
    element: <BlogPageOne />,
  },
  {
    path: 'why-saas-is-the-future-for-small-businesses-unpacking-the-digital-revolution',
    element: <BlogPageTwo />,
  },
  {
    path: 'the-ultimate-guide-to-building-your-first-mvp-steps-and-strategies',
    element: <BlogPageThree />,
  },
  {
    path: 'team',
    element: <TeamScreen />,
  },
  {
    path: ROUTES.signup,
    element: (
      <div>
        <MetaTitle title="Signup" />
        <h1>signup</h1>
      </div>
    ),
  },
]
