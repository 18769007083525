import { Helmet } from 'react-helmet'

export function MetaTitle({ title }: { title: string }) {
  return (
    <Helmet>
      <title>{title}</title>
      <meta property="og:title" content={title} />
    </Helmet>
  )
}
