import { IconNames, IconSize } from './consts'

export type IconProps = {
  name: IconNames
  color?: string
  size?: IconSize
  testId?: string | null
}

function Icon({ name, color = 'currentColor', size = IconSize.m, testId = '' }) {
  return (
    name && (
      <svg role="img" width={size} height={size} fill={color} data-testid={testId}>
        <use href={`#${name}`} />
      </svg>
    )
  )
}

export { Icon }
