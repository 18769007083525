import { DefaultOptions, QueryClient } from 'react-query'
import { HTTPErrorKind } from 'src/utils/http-error'

const typedDefaultOptions: DefaultOptions<any> = {
  queries: {
    refetchOnWindowFocus: false,
    staleTime: 3000000,
    retry: (count, error) => {
      switch (error.response?.status) {
        case HTTPErrorKind.NOT_FOUND:
          // case HttpStatusCodes.unauthorized:
          return false
        default:
          return count < 1
      }
    },
  },
}

export const queryClient = new QueryClient({
  defaultOptions: typedDefaultOptions as DefaultOptions<unknown>, // TS errors otherwise...
})
