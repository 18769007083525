import { config } from 'src/config/config'
import { apiCall } from 'src/utils/networking'

const saveEmail = ({ email, token }: any) => {
  const endpoint = '/muvon/subscribe'

  return apiCall(`${config.baseMailUrl}${endpoint}?email=${email}&challenge=${token}`, {
    method: 'POST',
  }) // eslint-disable-line
}

export { saveEmail }
