import { Scroller } from '../Scroller/Scroller'
import { TestimonialItem } from '../TestimonialItem/TestimonialItem'
import { testimonials } from './data'
import styles from './index.module.scss'

export function Testimonials() {
  return (
    <div className={styles.testimonial}>
      <h2>Trusted by businesses</h2>
      <p>Join the team</p>
      <Scroller direction="right" speed="slow">
        {testimonials.map((item) => (
          <TestimonialItem key={item.id} data={item} />
        ))}
      </Scroller>
    </div>
  )
}
