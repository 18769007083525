import './Scroller.css'

import { useEffect, useRef } from 'react'

export function Scroller({ direction, speed, children }) {
  const scrollerRef = useRef()

  useEffect(() => {
    if (!window.matchMedia('(prefers-reduced-motion: reduce)').matches) {
      // eslint-disable-next-line
      // @ts-ignore
      const scroller = scrollerRef.current
      // eslint-disable-next-line
      // @ts-ignore
      scroller.setAttribute('data-animated', true)
      // eslint-disable-next-line
      // @ts-ignore
      const scrollerInner = scroller.querySelector('.scroller__inner')
      const scrollerContent = Array.from(scrollerInner.children)

      scrollerContent.forEach((item) => {
        // eslint-disable-next-line
        // @ts-ignore
        const duplicatedItem = item.cloneNode(true)
        duplicatedItem.setAttribute('aria-hidden', true)
        scrollerInner.appendChild(duplicatedItem)
      })
    }
  }, [])

  return (
    // eslint-disable-next-line
    // @ts-ignore
    <div ref={scrollerRef} className="scroller" data-direction={direction} data-speed={speed}>
      <div className="scroller__inner">{children}</div>
    </div>
  )
}
