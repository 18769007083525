import { Button } from 'src/core/ds/button'
import { ButtonSizes, ButtonVariants } from 'src/core/ds/button/types'
import styles from './index.module.scss'

export function Upsell() {
  return (
    <section className={styles.try}>
      <h3>Start your small business today</h3>
      <p>We will do our best to help you start your small business in just one month.</p>

      <a style={{ width: '100%', display: 'flex', justifyContent: 'center' }} href="mailto:hello@muvon.io">
        <Button type={ButtonVariants.primary} size={ButtonSizes.sm} label="Launch Your MVP with Muvon" />
      </a>
    </section>
  )
}
