export enum IconNames {
  close = 'Close',
  copy = 'Copy',
  share = 'Share',
  plus = 'Plus',
  minus = 'Minus',
  closeMini = 'CloseMini',
  checkDouble = 'CheckDouble',
  check = 'Check',
  twitter = 'twitter',
  checkCircle = 'CheckCircle',
  checkFill = 'CheckFill',
  print = 'Print',
  globus = 'Globus',
  refresh = 'Refresh',
  download = 'Download',
  upload = 'Upload',
  search = 'Search',
  edit = 'Edit',
  compose = 'Compose',
  filter = 'Filter',
  exit = 'Exit',
  duplicate = 'Duplicate',
  moreVert = 'MoreVert',
  more = 'More',
  repeat = 'Repeat',
  processFill = 'ProcessFill',
  declineFill = 'DeclineFill',
  pauseFill = 'PauseFill',
  linkFill = 'LinkFill',
  camera = 'Camera',
  settings = 'Settings',
  install = 'Install',
  linkOpen = 'LinkOpen',
  dashboard = 'Dashboard',
  bell = 'Bell',
  gallery = 'Gallery',
  noteAdd = 'NoteAdd',
  glasses = 'Glasses',
  hamburger = 'Hamburger',
  promote = 'Promote',
  warning = 'Warning',
  infoCircle = 'InfoCircle',
  alertCircle = 'AlertCircle',
  alertFill = 'AlertFill',
  helpCircle = 'HelpCircle',
  eyeHide = 'EyeHide',
  eyeShow = 'EyeShow',
  fileAdd = 'FileAdd',
  fileMove = 'FileMove',
  attachment = 'Attachment',
  delete = 'Delete',
  imageAdd = 'ImageAdd',
  caretLeftMini = 'CaretLeftMini',
  caretRightMini = 'CaretRightMini',
  caretDownMini = 'CaretDownMini',
  caretUpMini = 'CaretUpMini',
  caretLeft = 'CaretLeft',
  caretRight = 'CaretRight',
  caretDown = 'CaretDown',
  caretUp = 'CaretUp',
  arrowLeft = 'ArrowLeft',
  arrowRight = 'ArrowRight',
  arrowUp = 'ArrowUp',
  arrowDown = 'ArrowDown',
  shevronLeft = 'ShevronLeft',
  shevronRight = 'ShevronRight',
  shevronUp = 'ShevronUp',
  shevronDown = 'ShevronDown',
  company = 'Company',
  shop = 'Shop',
  send = 'Send',
  chat = 'Chat',
  chatEmpty = 'ChatEmpty',
  phone = 'Phone',
  email = 'Email',
  calendarMove = 'CalendarMove',
  scheduled = 'Scheduled',
  calendar = 'Calendar',
  stopWatch = 'Stopwatch',
  stopWatchFast = 'StopwatchFast',
  stopWatchFill = 'StopwatchFill',
  rocket = 'Rocket',
  track = 'Track',
  ship = 'Ship',
  bankOut = 'BankOut',
  bankIn = 'BankIn',
  bank = 'Bank',
  pay = 'Pay',
  getPaid = 'GetPaid',
  directoryPayment = 'DirectoryPayment',
  contacts = 'Contacts',
  creditCard = 'CreditCard',
  debitCard = 'DebitCard',
  wireTransfer = 'WireTransfer',
  calculator = 'Calculator',
  bills = 'Bills',
  wallet = 'Wallet',
  moneyPayment = 'MoneyPayment',
  invoices = 'Invoices',
  moneyReceive = 'MoneyReceive',
  paperCheck = 'PaperCheck',
  billingSettings = 'BillingSettings',
  laptop = 'Laptop',
  smartphone = 'Smartphone',
  tabletEdit = 'TabletEdit',
  tabletSearch = 'TabletSearch',
  qrCode = 'QrCode',
  flow = 'Flow',
  international = 'International',
  user = 'User',
  userAdd = 'UserAdd',
  userRemove = 'UserRemove',
  userId = 'UserId',
  team = 'Team',
  userFill = 'UserFill',
  stopwatch = 'Stopwatch',
  cancelCircle = 'CancelCircle',
  cancelFill = 'CancelFill',
  sort = 'Sort',
  getStarted = 'Get_started',
  mailInbox = 'MailInbox',
  duplicateFlip = 'DuplicateFlip',
  installments = 'Installments',
  verifiedVendor = 'verified_vendor',
  shield = 'Shield',
}

export enum IconSize {
  xs = '16px',
  s = '18px',
  m = '20px',
  lg = '22px',
  xl = '24px',
  '2xl' = '26px',
}
