/* eslint-disable react/no-unescaped-entities */
import styles from './index.module.scss'

export function TestimonialItem({ data }) {
  return (
    <div className={styles.box}>
      <span>{data.title}</span>
      <p>{data.description}</p>
      <div className={styles.author}>
        <img src={data.authorImage} alt={data.authorName} />
        <div className={styles.authorName}>{data.authorName}</div>
      </div>
    </div>
  )
}
