export const blogData = {
  '1': {
    title: 'How to Validate Your Business Idea with a Well-Designed MVP',
    description:
      'Discover the essential steps to validate your business idea with a well-designed MVP. Learn how to create an MVP, gather user feedback, and pivot effectively for success. Featuring case studies from Dropbox to Airbnb.',
    image: 'https://api.picogen.io/files/202401/19/af886312730b137a3e1add19397143d8.png',
    date: '16.01.2024',
    link: 'how-to-validate-your-business-idea-with-well-designed-mvp',
    slug: 'how-to-validate-your-business-idea-with-well-designed-mvp',
  },
  '2': {
    title: 'Why SaaS is the Future for Small Businesses: Unpacking the Digital Revolution',
    description:
      'Dive into why SaaS is revolutionizing the game for small businesses. Understand how this powerful model can help scale operations, boost efficiency, and slash costs – all while offering flexibility and innovation.',
    image: 'https://api.picogen.io/files/202401/19/3d76a08085041848f1e7257eb26cbe33.png',
    date: '17.01.2024',
    link: 'why-saas-is-the-future-for-small-businesses-unpacking-the-digital-revolution',
    slug: 'why-saas-is-the-future-for-small-businesses-unpacking-the-digital-revolution',
  },
  '3': {
    title: 'The Ultimate Guide to Building Your First MVP: Steps and Strategies for Success',
    description: `Discover the essential steps and strategies for building your first Minimum Viable Product (MVP). Learn how a well-crafted MVP can benefit small businesses and startups, and set the stage for success in today's competitive market.`,
    image: 'https://api.picogen.io/files/202401/19/5c6cf351a20c2efaefd6d8af494febd7.png',
    date: '17.01.2024',
    link: 'the-ultimate-guide-to-building-your-first-mvp-steps-and-strategies',
    slug: 'the-ultimate-guide-to-building-your-first-mvp-steps-and-strategies',
  },
}
