import styles from './index.module.scss'

export function Faq() {
  return (
    <section id="faq" className={styles.faq}>
      <h2>Frequently Asked Questions (FAQs)</h2>
      <p>Find answers to your questions about launching your project.</p>

      <div className={styles.faqList}>
        <div className={styles.faqItem}>
          <span>What services does Muvon provide?</span>
          <p>
            Muvon is a dynamic software development agency dedicated to turning visionary ideas into tangible successes. We specialize in crafting Minimum Viable Products (MVPs), devising impactful launch strategies, and securing initial customer bases for startups and established businesses alike. Our holistic approach ensures a seamless journey from concept to market.
          </p>
        </div>

        <div className={styles.faqItem}>
          <span>How much does it cost to initiate a project with Muvon?</span>
          <p>
            Embarking on a project with Muvon begins at $3,000. This investment encompasses an all-inclusive package tailored to foster development, strategic launches, and robust customer acquisition efforts, ensuring your venture hits the ground running.
          </p>
        </div>

        <div className={styles.faqItem}>
          <span>What is the First Client Guarantee?</span>
          <p>
            Our unique First Client Guarantee underscores our commitment to your success. Should our strategic launch efforts not secure your first customer, we pledge a full refund. This assurance is a testament to our confidence in our comprehensive suite of launch strategies, meticulously designed around your project’s specifics.
          </p>
        </div>

        <div className={styles.faqItem}>
          <span>Where will my project be launched?</span>
          <p>
            Your project's launch platform is carefully selected from a variety of high-traffic sites such as Product Hunt, Hacker News, Reddit, and other pertinent directories. The choice is influenced by your specific package and pricing tier, aiming to optimize exposure and draw initial attention effectively.
          </p>
        </div>

        <div className={styles.faqItem}>
          <span>How does Muvon ensure the project's launch is successful?</span>
          <p>
            At Muvon, we extend beyond mere MVP development. We enrich your project with SEO optimization, prime it for a triumphant debut on platforms like Product Hunt, and position it within top directories. This multifaceted approach significantly boosts visibility, drawing potential visitors and setting the stage for success.
          </p>
        </div>

        <div className={styles.faqItem}>
          <span>What technologies does Muvon utilize?</span>
          <p>
            Our technological palette is extensive, featuring cutting-edge tools across SaaS (Software as a Service), AI (Artificial Intelligence), Blockchain, API integration, and beyond. Our tech stack is custom-selected to align with and elevate your project's unique needs.
          </p>
        </div>

        <div className={styles.faqItem}>
          <span>Is Muvon's work SEO optimized?</span>
          <p>
            Absolutely. SEO optimization is integral to our services, ensuring your project not only ascends the search engine rankings but also captures and retains visibility. This strategic emphasis on SEO aids in crafting a memorable and accessible online presence.
          </p>
        </div>

        <div className={styles.faqItem}>
          <span>Does Muvon provide post-launch support?</span>
          <p>
            Our dedication to your venture's longevity and prosperity extends well past the initial launch. Muvon offers continuous support, guiding your business through the evolving digital terrain and fostering sustained growth amidst the competitive marketplace.
          </p>
        </div>

        <div className={styles.faqItem}>
          <span>Why should I choose Muvon for my startup?</span>
          <p>
            Opting for Muvon means aligning with a partner renowned for its unparalleled development prowess, innovative launch strategies, and the distinct First Client Guarantee. Our track record of exceeding client expectations is mirrored in our glowing testimonials, illustrating our unwavering commitment to not just meeting but surpassing your aspirations.
          </p>
        </div>

        <div className={styles.faqItem}>
          <span>How Quickly Muvon Builds Projects</span>
          <p>
            The timeline for completing a project with Muvon entirely depends on how complex your project is. We specialize in developing MVPs (Minimum Viable Products) as well as launching startups that are ready for production. Typically, if we're working on an MVP, it usually takes about 1 month to go from the initial idea to acquiring the first customer. For more advanced startups requiring extensive functionality, the development process can extend to 3 months or longer.
          </p>
        </div>

        <div className={styles.faqItem}>
          <span>How is work organized to deliver the project?</span>
          <p>
            We begin by understanding your needs, providing an estimate, and signing a contract. The project development is divided into milestones. Throughout the development process, you will have private access to the website we are developing for you and will also be able to communicate with us on Slack. Typically, we start with a 50% upfront payment for each milestone and the remaining 50% after its completion. This approach ensures safety for you.
          </p>
        </div>

        <div className={styles.faqItem}>
          <span>Can I choose the technology stack?</span>
          <p>
            Even to speed up the process and quickly test the Minimum Viable Product (MVP), we build it on top of tools that we've developed over decades from various projects. This approach allows us to move very quickly, likely making us the fastest in the market. However, you still have the option to influence the choice of technology stack. We can build it in PHP, Rust, Go, TypeScript, and other popular languages. Choosing your own stack will require extra time and cost.
          </p>
        </div>
      </div>
    </section>
  )
}
