import { useMutation } from 'react-query'
import { saveEmail } from './api'

export const useEmailService = () => {
  const useSendEmail = () =>
    useMutation(saveEmail, {
      onMutate: (variables: any) => {
        if (!variables.token) {
          console.log('on mutate:', variables)
          throw Error('No token provided')
        }
      },
      onSuccess: (data: any) => {
        const [error] = data

        if (error) {
          throw Error(error)
        }
      },
      onError: (err: any) => {
        console.log('on mutate: err', err)

        return err
      },
    })

  return {
    useSendEmail,
  }
}
