import { Helmet } from 'react-helmet'
import { Blog } from 'src/components/Blog'
import { Faq } from 'src/components/Faq'
import { Upsell } from 'src/components/Upsell'
import { Container } from 'src/core/ds/container'
import { Icon, IconNames } from 'src/core/ds/icon'
import { AppLayout } from 'src/layouts/AppLayout'
import { Header } from 'src/layouts/Header'
import styles from './index.module.scss'

export function TeamScreen() {
  return (
    <AppLayout>
      <Helmet>
        <title>Team</title>
        <meta
          name="description"
          content="Discover the Zentask Influencer Program and monetize your content. Earn up to $2,500 per post."
        />
        <meta property="og:title" content="Influencer Program | Turn influence 💸 into money with Zentask.ai" />
        <meta
          property="og:description"
          content="Discover the Zentask Influencer Program and monetize your content. Earn up to $2,500 per post."
        />
      </Helmet>
      <Container>
        <Header />
        <div className={styles.content}>
          <div className={styles.hero}>
            <h1>Meet the Founders</h1>
          </div>

          <div className={styles.team}>
            <div className={styles.teamMember}>
              <img
                src="https://pbs.twimg.com/profile_images/1647989520703578112/UtgBAyGR_400x400.jpg"
                alt="Don Harman"
              />
              <div className={styles.teamMemberInfo}>
                <span>Don Hardman</span>
                <p>CEO & Co-founder</p>
                <div className={styles.links}>
                  {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                  <a href="https://twitter.com/donhardman88" title="twitter" target="_blank" rel="noreferrer">
                    <Icon color="var(--color-text)" name={IconNames.twitter} />
                  </a>
                </div>
              </div>
            </div>

            <div className={styles.teamMember}>
              <img
                src="https://pbs.twimg.com/profile_images/1631938866218258432/QRnxbWuj_400x400.jpg"
                alt="Vladimir Kosenko"
              />
              <div className={styles.teamMemberInfo}>
                <span>Vladimir Kosenko</span>
                <p>CMO & Co-founder</p>
                <div className={styles.links}>
                  {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                  <a href="https://twitter.com/web3brightside" title="twitter" target="_blank" rel="noreferrer">
                    <Icon color="var(--color-text)" name={IconNames.twitter} />
                  </a>
                </div>
              </div>
            </div>
          </div>

          <Blog />

          <Faq />

          <Upsell />
        </div>
      </Container>
    </AppLayout>
  )
}
