// import { Link } from '@tanstack/react-location'
import { Link } from '@tanstack/react-location'
import { useRef, useState } from 'react'
import Turnstile from 'react-turnstile'
import { Button } from 'src/core/ds/button'
import { ButtonSizes, ButtonVariants } from 'src/core/ds/button/types'
import { Container } from 'src/core/ds/container'
import { useEmailService } from 'src/services/emailService/hooks'
import { ReactComponent as MuvonLogo } from '../../assets/images/muvon-logo.svg'
import styles from './index.module.scss'

export function Footer() {
  const [token, setToken] = useState<any>(null)
  const emailService = useEmailService()
  const input = useRef<any>(null)

  const { isLoading, isSuccess, mutate } = emailService.useSendEmail()

  const isFormDisabled = isLoading || isSuccess || !token

  const onSubmit = async (e) => {
    e.preventDefault()

    if (!input?.current?.value.length) {
      return
    }

    await mutate({ email: input?.current?.value, token })
  }

  return (
    <footer id="footer" className={styles.footer}>
      <Container>
        <div className={styles.footerTop}>
          <div className={styles.nav}>
            <ul>
              <li className={styles.sectionTitle}>
                <b>Product:</b>
              </li>
              <li>
                <a href="/#features">Features</a>
              </li>

              <li>
                <a href="/#projects">Projects</a>
              </li>

              {/* <li>
                <a href="/#prices">Prices</a>
              </li> */}

              <li>
                <a href="/#blog">Blog</a>
              </li>

              <li>
                <a href="/#faq">FAQ</a>
              </li>
              {/* <li>
                <a href="/changelog">Changelog</a>
              </li> */}
            </ul>

            <ul>
              <li className={styles.sectionTitle}>
                <b>Company:</b>
              </li>
              <li>
                <a href="https://blog.muvon.io">Development blog</a>
              </li>
              <li>
                <Link to="/team">Team</Link>
              </li>
            </ul>

            <ul>
              <li className={styles.sectionTitle}>
                <b>Free tools:</b>
              </li>

              <li>TBD</li>
            </ul>

            <ul>
              <li className={styles.sectionTitle}>
                <b>Keep in touch:</b>
              </li>
              <li>
                <a rel="noreferrer" target="_blank" href="https://twitter.com/muvonteam">
                  Twitter
                </a>
              </li>
              <li>
                <a rel="noreferrer" target="_blank" href="https://www.linkedin.com/company/13463009/">
                  LinkedIn
                </a>
              </li>
              <li>
                <a href="/#footer">Subscribe</a>
              </li>
              <li>
                Contact us
                <br />
                <a href="mailto:hello@muvon.io">hello@muvon.io</a>
              </li>
            </ul>
          </div>

          <div className={styles.form}>
            <p>Subscribe</p>
            <form action="//mailer.muvon.dev/muvon/subscribe" method="POST" onSubmit={onSubmit}>
              <input
                disabled={isFormDisabled}
                placeholder="youremail@mail.com"
                ref={input}
                autoComplete="off"
                type="email"
                name="email"
                className={styles.input}
              />
              <Button
                disabled={isFormDisabled}
                isSubmit
                type={ButtonVariants.primary}
                size={ButtonSizes.sm}
                label="Subscribe"
              />
              <div className="turnstile">
                <Turnstile
                  // onExpire={() => alert('Token is expired. Try to reload the page if you want to submit your email')}
                  // onError={() => alert('Can not authenticate, try to reload the page')}
                  theme="light"
                  sitekey="0x4AAAAAAACGGKTMUtKok1XB"
                  onVerify={(token) => setToken(token)}
                />
              </div>
            </form>
          </div>
        </div>

        <div className={styles.footerBottom}>
          <div>
            <a title="Snaptap" href="/">
              <MuvonLogo />
            </a>
            <p>All rights reserved. 2024.</p>
          </div>
        </div>
      </Container>
    </footer>
  )
}
