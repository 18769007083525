import { Link } from '@tanstack/react-location'
// import { ReactComponent as DiscordLogo } from '../../assets/images/discord.svg'
// import { ReactComponent as FacebookLogo } from '../../assets/images/facebook.svg'
import { ReactComponent as LinkedinLogo } from '../../assets/images/linkedin.svg'
import { ReactComponent as MuvonLogo } from '../../assets/images/muvon-logo.svg'
import { ReactComponent as TwitterLogo } from '../../assets/images/twitter.svg'
import styles from './index.module.scss'

const socials = [
  // {
  //   link: 'https://web.facebook.com/muvonteam',
  //   icon: <FacebookLogo />,
  // },
  {
    link: 'https://twitter.com/muvonteam',
    icon: <TwitterLogo />,
  },
  {
    link: 'https://www.linkedin.com/company/13463009/',
    icon: <LinkedinLogo />,
  },
  // {
  //   link: 'https://discord.gg/Z5wjwyR78d',
  //   icon: <DiscordLogo />,
  // },
]

export function Header() {
  return (
    <header className={styles.header}>
      <Link to="/">
        <MuvonLogo />
      </Link>

      <ul className={styles.social}>
        {socials.map((social) => (
          <li key={social.link}>
            <a target="_blank" href={social.link} rel="noreferrer">
              {social.icon}
            </a>
          </li>
        ))}
      </ul>
    </header>
  )
}
