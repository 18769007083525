export enum ButtonVariants {
  tertiaryNaked = 'tertiaryNaked',
  secondary = 'secondary',
  primary = 'primary',
  error = 'error',
  success = 'success',
  tertiary = 'tertiary',
  unstyled = 'unstyled',
}

export enum ButtonSizes {
  lg = 'lg',
  md = 'md',
  sm = 'sm',
}
