import { Scroller } from '../Scroller/Scroller'
import styles from './index.module.scss'

const stack = [
  'Software as a Service',
  'Artificial Intelligence',
  'API Integration',
  'Blockchain',
  'E-commerce',
  'Fintech',
  'Open Source',
  'CMS',
]

export function TechStack() {
  return (
    <div className={styles.stack}>
      <div className={styles.line}>
        <Scroller direction="lefts" speed="slow">
          {stack.map((item, index) => (
            <div key={index} className={styles.lineItem}>
              {item}
            </div>
          ))}
        </Scroller>
      </div>
      <div className={styles.line}>
        <Scroller direction="right" speed="slow">
          {stack.map((item, index) => (
            <div key={index} className={styles.lineItem}>
              {item}
            </div>
          ))}
        </Scroller>
      </div>
    </div>
  )
}
