import { Blog } from 'src/components/Blog'
import { Faq } from 'src/components/Faq'
import { TechStack } from 'src/components/TechStack/TeckStack'
import { Testimonials } from 'src/components/Testimonials'
import { Upsell } from 'src/components/Upsell'
import { Button } from 'src/core/ds/button'
import { ButtonSizes, ButtonVariants } from 'src/core/ds/button/types'
import { Container } from 'src/core/ds/container'
import { GradientText } from 'src/core/ds/gradientText'
import { Icon, IconNames, IconSize } from 'src/core/ds/icon'
import { Header } from 'src/layouts/Header'
import BlogImage from '../../assets/images/blog.png'
import CointrapperfImage from '../../assets/images/cointrapper.png'
import KissCoreImage from '../../assets/images/kisscore-min.png'
import OvercloutImage from '../../assets/images/overclout-min.png'
import YodaImage from '../../assets/images/yoda-min.png'
import styles from './index.module.scss'

declare global {
  interface Window {
    cfToken: string
  }
}

export function LandingPage() {
  return (
    <Container>
      <Header />
      <div className={styles.hero}>
        <span className={styles.preTitle}>bringing ideas to life</span>
        <h1>
          <GradientText text="Seamless MVPs" />
          <br className={styles.desktopBR} />
          From Concept to Customer
          <span>.</span>
          {/* <GradientText text="Launch your SMB" />
          <br className={styles.desktopBR} />
          in 1 month */}
          {/* <GradientText text="Launch SMB in 1 month" />
          <br className={styles.desktopBR} />
          with exceptional
          <br className={styles.desktopBR} />
          <br className={styles.mobileBR} />
          SaaS & MVPs
          <span>.</span> */}
          {/* <span>
            <GradientText text="Muvon " />
          </span>
          –
          <br className={styles.mobileBR} />
          &nbsp;exceptional
          <br className={styles.desktopBR} />
          <br className={styles.mobileBR} />
          SaaS & MVPs
          <span>.</span> */}
          <br />
          <div style={{ marginTop: '2rem', fontSize: '34px', color: 'var(--color-primary)' }}>Starting at $3k</div>
        </h1>
        <p className={styles.description}>
          {/* Сommunity is filled with like-minded individuals who
          <br />
          are passionate about learning and growing as engineers. */}
          We launch turnkey projects, assisting not only with MVP development
          <br className={styles.desktopBR} />
          but also with launch strategies and acquiring initial customers.
        </p>

        <div className={styles.form}>
          <form method="POST" action="//mailer.muvon.dev/muvon/subscribe">
            {/* <input
              disabled={isFormDisabled}
              placeholder="youremail@mail.com"
              ref={input}
              autoComplete="off"
              type="email"
              name="email"
              className={styles.input}
            />
            <Button
              disabled={isFormDisabled}
              isSubmit
              type={ButtonVariants.primary}
              size={ButtonSizes.sm}
              label="Subscribe"
            /> */}
            <a style={{ width: '100%', display: 'flex', justifyContent: 'center' }} href="mailto:hello@muvon.io">
              <Button type={ButtonVariants.primary} size={ButtonSizes.sm} label="Launch Your MVP with Muvon" />
            </a>
          </form>
          {/* <p>Exclusive content, resources, and opportunities to connect with other members.</p> */}
          <p>First-Client Satisfaction Guarantee</p>
          {/* <div className="turnstile">
            <Turnstile
              // onExpire={() => alert('Token is expired. Try to reload the page if you want to submit your email')}
              // onError={() => alert('Can not authenticate, try to reload the page')}
              theme="light"
              sitekey="0x4AAAAAAACGGKTMUtKok1XB"
              onVerify={(token) => setToken(token)}
            />
          </div> */}
        </div>
      </div>

      <TechStack />

      <div className={styles.founders}>
        <div className={styles.founderScontainer}>
          <div className={styles.foundersAvatar}>
            <img
              src="https://imagedelivery.net/xBo-ZS_SWUoleWeSRokYNQ/d9fa67c3-e1c8-47cf-ad46-a8ebead1ca00/small"
              alt="Vladimir Kosenko"
            />
            <img
              src="https://imagedelivery.net/xBo-ZS_SWUoleWeSRokYNQ/b5b45ffe-b9f4-4ee0-b7ab-a4c2fc75bf00/small"
              alt="Don Hardman"
            />
          </div>

          <div>
            <span>Muvon founders</span>
            <div>
              <a target="_blank" href="https://twitter.com/donhardman88" rel="noreferrer">
                Don Hardman
              </a>
              &nbsp;&
              <br />
              <a target="_blank" href="https://twitter.com/web3brightside" rel="noreferrer">
                Vladimir Kosenko
              </a>
            </div>
          </div>
        </div>

        <div>
          <blockquote>
            <p>
              &#34;Elevate your business with cutting-edge software solutions from Muvon team. We're dedicated to
              transforming your ideas into reality through expert collaboration and the latest technological
              innovations. Let's code the future—your project deserves a team committed to excellence. Join us in
              creating software that sets you apart.&#34;
            </p>

            {/* {isSuccess && (
              <p>
                "Welcome onboard,
                <span>{input?.current?.value}</span>
                !"
              </p>
            )} */}
          </blockquote>
        </div>
      </div>

      <div id="features" className={styles.projects}>
        <h2>Exclusive features</h2>

        <div className={styles.services}>
          <div className={styles.serviceItem}>
            <h3>First-Client Satisfaction Guarantee</h3>
            <p>
              We guarantee exceptional quality and complete satisfaction on your initial project, laying the groundwork for a future filled with digital success.
            </p>

            <div className={styles.servicePreview}>
              <div className={styles.stripe}>
                <div className={styles.stripeImage}>
                  <img
                    src="https://du2ha4sdmc25q.cloudfront.net/stripe/stripe-slack-notifications/src_bdf7CiAHAARNd3WVChQ9ff8uVHZWNrjGGiPD/images/stripe-logo.png"
                    alt="stripe logo"
                  />
                </div>
                <div className={styles.stripeContent}>
                  <div>Stripe</div>
                  <span>
                    You received a payment of
                    <b> $60.00</b>
                  </span>
                  <p>from hello@muvon.io</p>
                </div>
              </div>

              <div className={styles.stripe}>
                <div className={styles.stripeImage}>
                  <img
                    src="https://du2ha4sdmc25q.cloudfront.net/stripe/stripe-slack-notifications/src_bdf7CiAHAARNd3WVChQ9ff8uVHZWNrjGGiPD/images/stripe-logo.png"
                    alt="stripe logo"
                  />
                </div>
                <div className={styles.stripeContent}>
                  <div>Stripe</div>
                  <span>
                    You received a payment of
                    <b> $70.00</b>
                  </span>
                  <p>from hello@muvon.io</p>
                </div>
              </div>
            </div>
          </div>

          <div className={styles.serviceItem}>
            <h3>SEO Optimization + Premiere Articles</h3>
            <p>
              Begin your ascent to the top of search rankings with our SEO-optimized content strategy, ensuring your brand isn't just seen—it's memorable.
            </p>

            <div className={styles.servicePreview}>
              <div className={styles.serviceSeo}>
                <div className={styles.serviceSeoItem}>
                  <div className={styles.serviceSeoItemCircle}>100</div>
                  <div className={styles.serviceSeoItemTitle}>Performance</div>
                </div>
                <div className={styles.serviceSeoItem}>
                  <div className={styles.serviceSeoItemCircle}>100</div>
                  <div className={styles.serviceSeoItemTitle}>Accessibility</div>
                </div>
                <div className={styles.serviceSeoItem}>
                  <div className={styles.serviceSeoItemCircle}>100</div>
                  <div className={styles.serviceSeoItemTitle}>Best Practices</div>
                </div>
                <div className={styles.serviceSeoItem}>
                  <div className={styles.serviceSeoItemCircle}>100</div>
                  <div className={styles.serviceSeoItemTitle}>SEO</div>
                </div>
              </div>
            </div>
          </div>

          <div className={styles.serviceItem}>
            <h3>Product Hunt Launch Support</h3>
            <p>We'll guide you through a strategic launch on Product Hunt, helping you capture initial momentum and traffic.</p>
          </div>

          <div className={styles.serviceItem}>
            <h3>Strategic Directory Listings</h3>
            <p>
              Boost your project's SEO and draw in early visitors by listing it in premier directories, carefully selected to enhance your online presence.
            </p>
          </div>
          <div className={styles.serviceItem}>
            <h3>After Launch Support</h3>
            <p>
              We'll manage your project and promptly address any issues or fix bugs as per our support agreement.
            </p>
          </div>
          <div className={styles.serviceItem}>
            <h3>Highload Ready</h3>
            <p>
              We offer code that performs well under heavy load and scales quickly in case your project takes off.
            </p>
          </div>
        </div>
      </div>

      <Testimonials />

      <div id="projects" className={styles.projects}>
        <h2>Ideas brought to life</h2>
        <div className={styles.projectsList}>
          <div className={styles.projectCard}>
            <div className={styles.projectCardContent}>
              <div>
                <div className={styles.projectCardBadge}>SaaS</div>
                <div className={styles.projectCardBadge}>AI</div>
              </div>
              <div className={styles.projectCardTitle}>Snaptap.ai</div>
              <div className={styles.projectCardDescription}>
                Professional photos without leaving your home. Surprise your friends and attract new followers.
              </div>
              <div className={styles.projectCardLink}>
                <a href="https://snaptap.ai" target="_blank" rel="noreferrer">
                  Visit website
                  <Icon name={IconNames.arrowRight} color="var(--color-primary)" size={IconSize.s} />
                </a>
              </div>
            </div>
            <div className={styles.projectCardImage}>
              <img
                height="325"
                style={{ objectFit: 'contain' }}
                src="https://imagedelivery.net/xBo-ZS_SWUoleWeSRokYNQ/7a656b13-d8bf-495e-1ee0-e35231f79600/medium"
                alt="Snaptap.ai"
              />
            </div>
          </div>

          <div className={styles.projectCard}>
            <div className={styles.projectCardContent}>
              <div>
                <div className={styles.projectCardBadge}>SaaS</div>
                <div className={styles.projectCardBadge}>AI</div>
                <div className={styles.projectCardBadge}>API</div>
              </div>
              <div className={styles.projectCardTitle}>Picogen.io</div>
              <div className={styles.projectCardDescription}>
                Integrate stunning image generation into your project today.
              </div>
              <div className={styles.projectCardLink}>
                <a href="https://picogen.io" target="_blank" rel="noreferrer">
                  Visit website
                  <Icon name={IconNames.arrowRight} color="var(--color-primary)" size={IconSize.s} />
                </a>
              </div>
            </div>
            <div className={styles.projectCardImage}>
              <img
                height="325"
                src="https://imagedelivery.net/xBo-ZS_SWUoleWeSRokYNQ/e7637fea-3549-41aa-2bd2-c1ee2ba5e500/medium"
                alt="Picogen.io"
              />
            </div>
          </div>

          <div className={styles.projectCard}>
            <div className={styles.projectCardContent}>
              <div>
                <div className={styles.projectCardBadge}>SaaS</div>
                <div className={styles.projectCardBadge}>AI</div>
                <div className={styles.projectCardBadge}>Marketplace</div>
              </div>
              <div className={styles.projectCardTitle}>Zentask.ai</div>
              <div className={styles.projectCardDescription}>
                AI-Powered Solutions to Simplify Your Work & Boost Productivity.
              </div>
              <div className={styles.projectCardLink}>
                <a href="https://zentask.ai" target="_blank" rel="noreferrer">
                  Visit website
                  <Icon name={IconNames.arrowRight} color="var(--color-primary)" size={IconSize.s} />
                </a>
              </div>
            </div>
            <div className={styles.projectCardImage}>
              <img
                height="325"
                src="https://imagedelivery.net/xBo-ZS_SWUoleWeSRokYNQ/579962c3-3d78-4dae-ad7f-aa0194645100/medium"
                alt="Zentask.ai marketplace"
              />
            </div>
          </div>

          <div className={styles.projectCard}>
            <div className={styles.projectCardContent}>
              <div>
                <div className={styles.projectCardBadge}>blog</div>
              </div>
              <div className={styles.projectCardTitle}>Muvon Blog</div>
              <div className={styles.projectCardDescription}>
                Read articles on dev tricks, startups, crypto, web3, coding, patterns, tech news & more.
              </div>
              <div className={styles.projectCardLink}>
                <a href="https://blog.muvon.io" target="_blank" rel="noreferrer">
                  Visit blog
                  <Icon name={IconNames.arrowRight} color="var(--color-primary)" size={IconSize.s} />
                </a>
              </div>
            </div>
            <div className={styles.projectCardImage}>
              <img height="325" src={BlogImage} alt="Muvon community blog" />
            </div>
          </div>

          <div className={styles.projectCard}>
            <div className={styles.projectCardContent}>
              <div>
                <div className={styles.projectCardBadge}>crypto</div>
                <div className={styles.projectCardBadge}>upcoming</div>
              </div>
              <div className={styles.projectCardTitle}>Cointrapper</div>
              <div className={styles.projectCardDescription}>
                Automated AI-powered crypto trading algorithm as a service. Trading on autopilot.
              </div>
              <div className={styles.projectCardLink}>
                <a href="https://cointrapper.com" target="_blank" rel="noreferrer">
                  Visit website
                  <Icon name={IconNames.arrowRight} color="var(--color-primary)" size={IconSize.s} />
                </a>
              </div>
            </div>
            <div className={styles.projectCardImage}>
              <img height="325" src={CointrapperfImage} alt="Cointrapper landing page" />
            </div>
          </div>

          <div className={styles.projectCard}>
            <div className={styles.projectCardContent}>
              <div className={styles.projectCardBadge}>Open source</div>
              <div className={styles.projectCardTitle}>KISSCore</div>
              <div className={styles.projectCardDescription}>
                The lightweight single file packed end-project framework for rapid development of very fast projects.
              </div>
              <div className={styles.projectCardLink}>
                <a href="https://github.com/Muvon/kisscore" target="_blank" rel="noreferrer">
                  Check on GitHub
                  <Icon name={IconNames.arrowRight} color="var(--color-primary)" size={IconSize.s} />
                </a>
              </div>
            </div>
            <div className={styles.projectCardImage}>
              <img height="325" src={KissCoreImage} alt="KISS Core" />
            </div>
          </div>

          <div className={styles.projectCard}>
            <div className={styles.projectCardContent}>
              <div className={styles.projectCardBadge}>Open source</div>
              <div className={styles.projectCardTitle}>Yoda</div>
              <div className={styles.projectCardDescription}>
                Simple tool to dockerize and manage deployment of your project.
              </div>
              <div className={styles.projectCardLink}>
                <a href="https://github.com/Muvon/yoda" target="_blank" rel="noreferrer">
                  Check on GitHub
                  <Icon name={IconNames.arrowRight} color="var(--color-primary)" size={IconSize.s} />
                </a>
              </div>
            </div>
            <div className={styles.projectCardImage}>
              <img height="325" src={YodaImage} alt="Yoda" />
            </div>
          </div>

          <div className={styles.projectCard}>
            <div className={styles.projectCardContent}>
              <div>
                <div className={styles.projectCardBadge}>web3</div>
                <div className={styles.projectCardBadge}>upcoming</div>
              </div>
              <div className={styles.projectCardTitle}>Overclout</div>
              <div className={styles.projectCardDescription}>
                Web3 social network with tipping and a unique gaming experience.
              </div>
              <div className={styles.projectCardLink}>
                <a href="https://overclout.com" target="_blank" rel="noreferrer">
                  Visit website
                  <Icon name={IconNames.arrowRight} color="var(--color-primary)" size={IconSize.s} />
                </a>
              </div>
            </div>
            <div className={styles.projectCardImageCentered}>
              <img height="325" src={OvercloutImage} alt="Overclout" />
            </div>
          </div> 
        </div>
      </div>

      <Blog />
      <Faq />
      <Upsell />
    </Container>
  )
}
