import { Outlet, Router } from '@tanstack/react-location'
import { QueryClientProvider } from 'react-query'
import { Bounce, ToastContainer } from 'react-toastify'
import { IconsSpriteContainer } from './core/ds/iconsSprite'
import { AppLayout } from './layouts/AppLayout'
import { Footer } from './layouts/Footer'
import { queryClient } from './queries/reactQueryClient'
import { location, routes } from './Routes'

export function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <IconsSpriteContainer />
      <AppLayout>
        <Router routes={routes} location={location}>
          <Outlet />
          <Footer />
        </Router>
      </AppLayout>
      <ToastContainer transition={Bounce} closeButton className="MIToastContainer" toastClassName="MIToastInner" />
    </QueryClientProvider>
  )
}
