export enum HTTPErrorKind {
  INFORMATION = 100,
  SUCCESS = 200,
  REDIRECT = 300,
  CLIENT = 400,
  NOT_FOUND = 404,
  SERVER = 500,
}

function determineKind(status: number): HTTPErrorKind {
  if (status >= 100 && status < 200) return HTTPErrorKind.INFORMATION

  if (status < 300) return HTTPErrorKind.SUCCESS

  if (status < 400) return HTTPErrorKind.REDIRECT

  if (status < 500) return HTTPErrorKind.CLIENT

  if (status < 600) return HTTPErrorKind.SERVER

  throw new Error(`Unknown HTTP status code ${status}`)
}

/** @param {HTTPErrorKind} kind */
export class HTTPError extends Error {
  // eslint-disable-line
  public readonly kind: HTTPErrorKind

  constructor(info: Response, message: string) {
    super(`HTTPError [status: ${info.statusText} (${info.status})]\n${message}`)
    this.kind = determineKind(info.status)
  }
}

export function handleApiResponse<T>(response: T) {
  if (Array.isArray(response)) {
    const [[error, data]] = response

    return [error, data]
  }

  return [response[1], null]
}
